import { Text, Center, Modal } from '@mantine/core';
import { useState } from 'react';
import ForgotPasswordForm from './ForgotPasswordForm';
export default function ForgotPassword() {
  const [modalOpened, setModalOpened] = useState(false);
  return (
    <>
      <Center>
        <Text
          c="white"
          onClick={() => {
            setModalOpened(true);
          }}
        >
          Forgot password
        </Text>
      </Center>
      <Modal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        title="Forgot Password"
      >
        <ForgotPasswordForm />
      </Modal>
    </>
  );
}
