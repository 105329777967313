import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useForm } from '@mantine/form';
import {
  TextInput,
  PasswordInput,
  Group,
  Button,
  // Checkbox,
  Stack,
  Alert,
  Text,
} from '@mantine/core';
import Link from 'next/link';

export default function AuthenticationForm(props) {
  const supabase = useSupabaseClient();
  const [error, setError] = useState(props.error);
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();

  const form = useForm({
    initialValues: {
      email: props.params?.type === 'otp' ? props.params.email : '',
      password: '',
      token: props.params?.type === 'otp' ? props.params.token : '',
    },
    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
      password: (val, values) =>
        !values.token && val.length <= 5
          ? 'Password should include at least 6 characters'
          : null,
    },
  });

  // useEffect(() => {
  //   if (props.params?.type === 'otp') {
  //     const { email, token } = props.params;
  //     form.setValues({ email, token });
  //   }
  // }, [props.params]);

  const handleAuthentication = async (email, password, token) => {
    setError(false);
    if (password) {
      const { data, error: signInError } =
        await supabase.auth.signInWithPassword({
          email,
          password,
        });

      if (signInError) {
        console.error(signInError);
        setError({
          error: 'invalid_credentials',
          error_code: 401,
          error_description: 'Invalid login credentials',
        });
        setIsLoading(false);
      } else {
        router.push('/' + data.user.app_metadata.role);
      }
    }

    if (token) {
      const { data, error } = await supabase.auth.verifyOtp({
        email,
        token,
        type: 'recovery',
      });
      if (error) {
        console.error(error);
        setError({
          error: 'invalid_token',
          error_code: 401,
          error_description: 'Token has expired or is invalid',
        });
        setIsLoading(false);
      } else {
        router.push('/settings');
      }
    }
  };


  

  return (
    <form
      onSubmit={form.onSubmit(() => {
        setIsLoading(true);
        handleAuthentication(
          form.values.email,
          form.values.password,
          form.values.token,
        );
      })}
    >
      <Stack>
        {error && (
          <Alert title="Error">
            <Text>{error.error_description}.</Text>
          </Alert>
        )}

{props.params?.type !== 'confirmation' && (

      <>
          <TextInput
          required
          placeholder="Email"
          value={form.values.email}
          onChange={(event) =>
            form.setFieldValue('email', event.currentTarget.value)
          }
          error={form.errors.email && 'Invalid email'}
        />
          <PasswordInput
            required
            placeholder="Password"
            value={form.values.password}
            onChange={(event) =>
              form.setFieldValue('password', event.currentTarget.value)
            }
            error={
              form.errors.password &&
              'Password should include at least 6 characters'
            }
          />
          </>
          )}

        {/* <Checkbox
          label="Remember me"
          checked={form.values.terms}
          onChange={(event) =>
            form.setFieldValue('terms', event.currentTarget.checked)
          }
        /> */}
      </Stack>
      <Group position="apart" mt="xl">
      {props.params?.type === 'confirmation' ? (

        <Link href={props.params.url}>
          <Button>Click here to login</Button>
         </Link>
      ) : (
        <Button type="submit" loading={isLoading}>
          {isLoading ? 'Logging' : 'Log'} in
        </Button>
      )}
      </Group>
    </form>
  );
}
