import { Loader, Flex, Stack, AppShell, Title } from "@mantine/core";
import AuthenticationForm from "../components/auth/AuthenticationForm";
import ForgotPassword from "../components/auth/ForgotPassword";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import queryString from "query-string";

export default function LoginPage() {
  const router = useRouter();
  const supabase = useSupabaseClient();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [params, setParams] = useState(null);

  useEffect(() => {
    const {
      data: { subscription: authListener },
    } = supabase.auth.onAuthStateChange((event) => {
      const path = router.asPath.slice(2);
      const params = queryString.parse(path);
      console.log(event);
      //password reset redirect hack as recovery event not working
      // if (event === 'SIGNED_IN' && (params.type === 'confirmation' || params.type==='recovery')) {
      // if (event === 'SIGNED_IN' && params.type === 'recovery') {
      if (event === "SIGNED_IN" || event === "PASSWORD_RECOVERY") {
        router.push("/settings");
      } else {
        setLoading(false);
      }
    });
    return () => {
      authListener?.unsubscribe();
    };
  }, [router, supabase.auth]);

  useEffect(() => {
    const path = router.asPath.slice(2);
    const params = queryString.parse(path);
    // console.log(params);

    // if (params.type === 'otp') {
    //   setParams(params);
    // }
    if (params.type === "confirmation" && params.url) {
      console.log(params);
      setParams(params);
    }
    if (params.type !== "recovery") {
      setLoading(false);
    }

    if (params.error) {
      setError(params);
    }
  }, [router]);

  if (loading) {
    return (
      <Flex sx={{ height: "100vh" }} justify="center" align="center">
        <Loader />
      </Flex>
    );
  } else {
    return (
      <AppShell
        sx={{
          backgroundImage: "url(/login-background.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          paddingLeft: 0,
        }}
      >
        <Flex align="center" sx={{ height: "100vh" }}>
          <Stack
            sx={{
              width: 500,
              padding: "92px 70px 38px 58px",
              border: "solid white 3px",
              borderRadius: "96px",
              marginLeft: 78,
            }}
          >
            <Title
              order={1}
              size={36}
              weight={800}
              tt="uppercase"
              c="white"
              ta="center"
              sx={{ marginBottom: 12 }}
            >
              Login
            </Title>
            <AuthenticationForm error={error} params={params} />
            {params?.type !== "confirmation" && <ForgotPassword />}
          </Stack>
        </Flex>
      </AppShell>
    );
  }
}
