import { useSupabaseClient } from '@supabase/auth-helpers-react';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { useForm } from '@mantine/form';
import {
  TextInput,
  PasswordInput,
  Group,
  Button,
  Checkbox,
  Stack,
  Alert,
} from '@mantine/core';

export default function ForgotPasswordForm(props) {
  const supabase = useSupabaseClient();
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const form = useForm({
    initialValues: {
      email: '',
    },
    validate: {
      email: (val) => (/^\S+@\S+$/.test(val) ? null : 'Invalid email'),
    },
  });
  const handleForgotPassword = async (email, password) => {
    setIsLoading(true);
    setError(false);
    const { data, error } = await supabase.auth.resetPasswordForEmail(email);

    if (error) {
      setError(error);
      console.error(error);
    } else {
      setSuccess(true);
    }
    setIsLoading(false);
  };

  return (
    <form
      onSubmit={form.onSubmit(() => {
        handleForgotPassword(form.values.email);
      })}
    >
      <Stack>
        {error && (
          <Alert title="Error">
            {typeof error === 'object' ? error.name : error}
            <br />
            If you can&apos;t fix this please contact your administrator.
          </Alert>
        )}
        {success && (
          <Alert title="Success" color="green">
            Please check your email for a link to reset your password
          </Alert>
        )}

        <TextInput
          required
          label="Email"
          value={form.values.email}
          onChange={(event) =>
            form.setFieldValue('email', event.currentTarget.value)
          }
          error={form.errors.email && 'Invalid email'}
        />
      </Stack>
      <Group position="apart" mt="xl">
        <Button type="submit" loading={isLoading}>
          {isLoading ? 'Sending' : 'Send'} reset link
        </Button>
      </Group>
    </form>
  );
}
